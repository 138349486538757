import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'

const accesstoken = process.env.GATSBY_MULTICYCLES_API_TOKEN;
// const envToken = process.env.GATSBY_ENV_TOKEN;


// console.log('accesstoken :', accesstoken);
// console.log('envToken :', envToken);
export const mcClient = new ApolloClient({
    uri: `https://api.multicycles.org/v1?access_token=${accesstoken}`,
    fetch
});