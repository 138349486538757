module.exports = [{
      plugin: require('/tmp/4ba6b174/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/4ba6b174/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/4ba6b174/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
