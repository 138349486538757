// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-provider-page-js": () => import("/tmp/4ba6b174/src/templates/providerPage.js" /* webpackChunkName: "component---src-templates-provider-page-js" */),
  "component---src-pages-404-js": () => import("/tmp/4ba6b174/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/tmp/4ba6b174/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/tmp/4ba6b174/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-provider-list-js": () => import("/tmp/4ba6b174/src/pages/provider-list.js" /* webpackChunkName: "component---src-pages-provider-list-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/4ba6b174/.cache/data.json")

